<template>
  <b-modal
    id="create-client-modal"
    ref="create-client-modal"
    title="Create Client"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <!-- !! First Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="first-name"
              rules="required"
            >
              <HeroInputText
                id="first-name"
                v-model="client.first_name"
                label="First Name"
                column="4"
                required
                :readonly="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Last Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="last-name"
              rules=""
            >
              <HeroInputText
                id="last-name"
                v-model="client.last_name"
                label="Last Name"
                column="4"
                :readonly="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Email -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required"
            >
              <HeroInputText
                id="email"
                v-model="client.email"
                label="Email"
                column="4"
                required
                :readonly="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Password -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="required|min:8"
            >
              <HeroInputText
                id="password"
                v-model="client.password"
                label="Password"
                column="4"
                required
                :readonly="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Listen Call Record -->
          <b-col cols="12">
            <HeroCheckbox
              id="listen-call-record"
              v-model="client.listen_call_record"
              label="Listen Call Record"
              column="4"
            />
          </b-col>

          <!-- !! Add/Edit Status -->
          <b-col cols="12">
            <HeroCheckbox
              id="edit-status"
              v-model="client.edit_status"
              label="Add/Edit Status"
              column="4"
            />
          </b-col>

          <!-- !! Download -->
          <b-col cols="12">
            <HeroCheckbox
              id="download-leads"
              v-model="client.download_leads"
              label="Download Leads"
              column="4"
            />
          </b-col>
        </b-row>

        <hr>
        <!-- !! Demo Account -->
        <b-form-group
          label-for="demo-account"
          :label-cols-md="5"
          class="align-items-center"
        >
          <template #label>
            <span style="font-size: 1rem; font-weight: 600;">
              Demo Account
            </span>
          </template>
          <HeroCheckbox
            id="demo-account"
            v-model="client.demoAccount"
            class="mb-0"
            @input="handleDemoAccountInput"
          />
        </b-form-group>

        <validation-provider
          #default="{ errors }"
          ref="displayLeadPeriod"
          name="display lead period"
          :rules="client.demoAccount ? 'demo_display_lead_range' : ''"
        >
          <b-form-group
            label-for="display-lead-period"
            :label-cols-md="5"
          >
            <template #label>
              <span style="font-size: 1rem; font-weight: 600;">
                Display Lead Period <span class="text-danger">*</span>
              </span>
            </template>
            <date-range-picker
              v-model="client.demoDisplayLeadRange"
              opens="left"
              :class="[
                'demo-display-lead-range',
                { 'disabled': !client.demoAccount, 'error': errors.length > 0 }
              ]"
              style="width: 100%"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              show-dropdowns
              :ranges="false"
              :disabled="!client.demoAccount"
            >
              <template
                #input="picker"
                style="min-width: 350px;"
              >
                {{ picker.startDate | demoDisplayLeadRangeFormat }} - {{ picker.endDate | demoDisplayLeadRangeFormat }}
              </template>
            </date-range-picker>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import ErrorService from '@/services/ErrorService'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import DateRangePicker from 'vue2-daterange-picker'
import SweetAlert from '@/services/SweetAlert'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import axiosInstance from '@/libs/axiosInstance'

// CSS
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'

extend('demo_display_lead_range', {
  message: 'The {_field_} field is required',
  validate(value) {
    return value.startDate !== null && value.endDate !== null
  },
})

export default {
  components: {
    HeroInputText,
    HeroCheckbox,
    HeroButtonAction,
    DateRangePicker,
    ValidationObserver,
    ValidationProvider,
  },
  filters: {
    demoDisplayLeadRangeFormat(date) {
      return date ? moment(date).tz('Asia/Bangkok').format('DD MMMM YYYY') : ''
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,
      original: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        demoAccount: false,
        demoDisplayLeadRange: {
          startDate: null,
          endDate: null,
        },
        listen_call_record: true,
        edit_status: true,
        download_leads: true,
      },
      client: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        demoAccount: false,
        demoDisplayLeadRange: {
          startDate: null,
          endDate: null,
        },
        listen_call_record: true,
        edit_status: true,
        download_leads: true,
      },
    }
  },
  methods: {
    doCloseModal() {
      this.client = { ...this.original }
      this.$refs['create-client-modal'].hide()
    },

    handleDemoAccountInput() {
      if (!this.client.demoAccount) {
        this.client.demoDisplayLeadRange = {
          startDate: null,
          endDate: null,
        }
      }
      this.$refs.displayLeadPeriod.reset()
    },

    async validationForm() {
      let alertResult
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        const { startDate, endDate } = this.client.demoDisplayLeadRange

        const body = {
          first_name: this.client.first_name,
          last_name: this.client.last_name,
          email: this.client.email,
          password: this.client.password,
          demo_account: this.client.demoAccount,
          demo_display_lead_start_date: startDate ? moment(startDate).tz('Asia/Bangkok').format('YYYY-MM-DD') : null,
          demo_display_lead_end_date: endDate ? moment(endDate).tz('Asia/Bangkok').format('YYYY-MM-DD') : null,
          listen_call_record: this.client.listen_call_record,
          edit_status: this.client.edit_status,
          download_leads: this.client.download_leads,
        }

        try {
          const response = await axiosInstance.post('client/create-client', body)
          const clientId = response.data.data.id
          await this.$store.dispatch('ClientOptions/fetchOptions')

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.client = { ...this.original }
            this.$refs['create-client-modal'].hide()
            this.$emit('success', clientId)
          }
        } catch (error) {
          this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        } finally {
          this.showOverlay = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.demo-display-lead-range {
  width: 100%;
  &.error {
    ::v-deep.form-control {
      padding-right: calc(1.45em + 0.876rem);
      border-color: #ea5455;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
      background-position: right calc(0.3625em + 0.219rem) center;
    }
  }
  &.disabled {
    ::v-deep.form-control {
      background-color: #efefef;
    }
  }
}
</style>
